import { Provider } from 'mobx-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';

import AppBase from '@eag/sales-app-base/build/app/AppBase';
import { authenticationService } from '@eag/sales-app-base/build/app/core/service/AuthenticationService';
import { errorService } from '@eag/sales-app-base/build/app/core/service/ErrorService';
import { loyaltyService } from '@eag/sales-app-base/build/app/core/service/LoyaltyService';
import {
	BlueInputConfigValues,
	BlueInputContext,
	defaultBlueInputConfig,
} from '@eag/sales-app-base/build/app/library/components/form/BlueInputContext';

import 'eportal-assets/docroot/assets/css/style.css';
import LoginPage from './app/public/pages/LoginPage';
import Footer from './app/shared/component/Footer';
import Header from './app/shared/component/Header';
import Navigation from './app/shared/component/navigation/Navigation';

const container = document.getElementById('root');
const root = createRoot(container!);
const inputConfig: BlueInputConfigValues = {
	...defaultBlueInputConfig,
	floatLabel: true,
	maskSlotChar: '',
	truncateInfoText: true,
	labelPosition: 'afterInput',
	helpTootipButtonClassName: 'button naked info icon-only help-tooltip-button',
};

root.render(
	<React.StrictMode>
		<Router>
			<Provider
				loyaltyService={loyaltyService}
				errorService={errorService}
				authenticationService={authenticationService}>
				<BlueInputContext.Provider value={inputConfig}>
					<AppBase navigation={<Navigation />} header={<Header />} footer={<Footer />} loginPage={<LoginPage />} />
				</BlueInputContext.Provider>
			</Provider>
		</Router>
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
