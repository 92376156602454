import routes from '@eag/sales-app-base/build/app/core/routes/routes';
import { NumberFormatter } from '@eag/sales-app-base/build/app/core/utils/formatter/NumberFormatter';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
	points: number;
}

const BonusPointsHeaderComponent: React.FC<Props> = (props) => {
	return (
		<Link to={routes.portal.bonus.base.path} className="bonus">
			<div className="points">
				<span>{NumberFormatter.formatWithFixedFractionDigits(props.points, 0)}</span>
			</div>
		</Link>
	);
};

export default BonusPointsHeaderComponent;
