import { PartnerModelFeaturesEnum } from '@eag/sales-app-base/build/app/core/rest-api/model';
import { portalPaths, publicPaths } from '@eag/sales-app-base/build/app/core/routes/paths';
import routes, { RouteModel } from '@eag/sales-app-base/build/app/core/routes/routes';
import { shoppingCart } from '@eag/sales-app-base/build/app/core/store/ShoppingCart';
import { useAuthenticationService } from '@eag/sales-app-base/build/app/core/utils/ContextHelper';
import ShoppingCartNavItem from '@eag/sales-app-base/build/app/shared/component/navigation/ShoppingCartNavItem';
import { observer } from 'mobx-react';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

const AccountNavItem: React.FC = observer(() => {
	const navigate = useNavigate();
	const profileOverlayPanel = useRef<OverlayPanel>(null);
	const authenticationService = useAuthenticationService();
	const temporary = authenticationService.currentSession.get().user.temporary;
	const showShoppingCart = !!shoppingCart.data?.product?.id;

	const { t } = useTranslation();

	useEffect(() => {
		const handleScroll = () => profileOverlayPanel.current?.align();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function logout() {
		authenticationService
			.logout(() => navigate(publicPaths.login))
			.catch((e) => console.error('Error during logout', e));
	}

	const toggleProfileOverlay = (event: React.SyntheticEvent<Element, Event>) => {
		if (profileOverlayPanel.current) {
			profileOverlayPanel.current.toggle(event);
		}
	};

	const route: RouteModel = routes.portal.profile;
	return authenticationService.isAuthenticated() ? (
		<>
			<li className="user-menu">
				<ul className="account-items">
					{!temporary && <Divider layout="vertical" />}
					{showShoppingCart && (
						<li>
							<ShoppingCartNavItem />
						</li>
					)}
					{authenticationService.hasFeature(PartnerModelFeaturesEnum.INBOX) && (
						<li>
							<NavLink className="button inbox-button default" to={portalPaths.inbox} end />
						</li>
					)}
					{temporary ? (
						<li>
							<button className="logout button naked action" onClick={() => logout()}>
								<span>{t('navigation.account.logout.label')}</span>
							</button>
						</li>
					) : (
						<>
							<li className="profileButtonList">
								<OverlayPanel ref={profileOverlayPanel} unstyled>
									<ul className="popup">
										<li>
											<NavLink to={route.path} end={true} target={route.externalSite ? '_blank' : undefined}>
												<button className="profile button naked" onClick={toggleProfileOverlay}>
													<span>{t('navigation.menu.profile.label')}</span>
												</button>
											</NavLink>
										</li>
										<li>
											<button className="logout button naked" onClick={() => logout()}>
												<span>{t('navigation.account.logout.label')}</span>
											</button>
										</li>
									</ul>
								</OverlayPanel>
								<div className="profileButtonContainer">
									<button className="button profile default" onClick={toggleProfileOverlay} />
								</div>
							</li>
						</>
					)}
				</ul>
			</li>
		</>
	) : null;
});

AccountNavItem.displayName = 'AccountNavItem';

export { AccountNavItem };
