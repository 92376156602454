import { PartnerModelFeaturesEnum } from '@eag/sales-app-base/build/app/core/rest-api/model';
import { DASHBOARD_PAGE, portalPaths, publicPaths } from '@eag/sales-app-base/build/app/core/routes/paths';
import {
	RouteModel,
	portalRoutesValuesAsFlatArray,
	routesValuesAsFlatArray,
} from '@eag/sales-app-base/build/app/core/routes/routes';
import { authenticationService } from '@eag/sales-app-base/build/app/core/service/AuthenticationService';
import { loyaltyService } from '@eag/sales-app-base/build/app/core/service/LoyaltyService';
import { shoppingCart } from '@eag/sales-app-base/build/app/core/store/ShoppingCart';
import ShoppingCartNavItem from '@eag/sales-app-base/build/app/shared/component/navigation/ShoppingCartNavItem';
import logo from 'eportal-assets/docroot/assets/img/brand/logo-2024-2.svg';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { NavLink, matchPath, useNavigate } from 'react-router-dom';

interface Props {
	visible: boolean;
	hide: (val: boolean) => void;
}

export const SidebarComponent = (props: Props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const user = authenticationService?.currentSession.get().user;
	const loyaltyPoints = loyaltyService.currentPoints.get()?.points;
	const { temporary, login, businessPartnerNumbers } = user;

	const logout = () => {
		authenticationService
			.logout(() => navigate(publicPaths.login))
			.catch((e) => console.error('Error during logout', e));
	};
	const showShoppingCart = !!shoppingCart.data?.product?.id;
	const sideBarHeader = () => {
		return (
			<>
				<div className="header">
					<a className="logo" href={temporary ? publicPaths.directOrder.base : DASHBOARD_PAGE}>
						<img src={logo} alt={t('navigation.logo.alt')} />
					</a>
				</div>
				<div className="account-items">
					{showShoppingCart && <ShoppingCartNavItem />}
					{authenticationService.hasFeature(PartnerModelFeaturesEnum.INBOX) && (
						<NavLink
							className="button inbox-button default"
							to={portalPaths.inbox}
							end
							onClick={() => props.hide(true)}
						/>
					)}
					<button className="close-button button" onClick={() => props.hide(true)} />
				</div>
			</>
		);
	};

	const routesWithNavigation = portalRoutesValuesAsFlatArray.filter(
		(route) => route.navigation && route.navigation() === true
	);

	const currentRoute = routesValuesAsFlatArray.find((route) =>
		matchPath({ path: route.path, end: true }, location.pathname)
	);
	return (
		<Sidebar
			className="sidebar-style"
			visible={props.visible}
			showCloseIcon={false}
			onHide={() => props.hide(true)}
			dismissable
			position="right"
			header={sideBarHeader}>
			<Divider />
			<div className="sidebar-content">
				<li>
					<a href={DASHBOARD_PAGE} rel="noopener noreferrer" className={classNames('uebersicht', 'sidebar-item')}>
						<span>{t('navigation.menu.homepage.label')}</span>
					</a>
				</li>
				{routesWithNavigation.map((route: RouteModel) => {
					const highlightNavItem = currentRoute?.highlightedNavEntryPath === route.path;
					return (
						<li key={route.name}>
							<NavLink
								key={route.name}
								onClick={() => props.hide(true)}
								to={route.path}
								end={true}
								target={route.externalSite ? '_blank' : undefined}
								className={({ isActive }) =>
									classNames({ active: highlightNavItem || isActive }, 'sidebar-item', route.className)
								}>
								<span className={classNames(route.path)}>{route.name}</span>
								{route.path === portalPaths.bonus.base && <span className="points">{loyaltyPoints}</span>}
							</NavLink>
						</li>
					);
				})}
			</div>
			<div className="sidebar-footer">
				<div className="footer-name">
					<span className="name">{login}</span>
				</div>
				<div className="customer-number">
					<span>{t('navigation.menu.businessPartnerNumber.label')}: </span>
					<span className="number">{businessPartnerNumbers?.[0] ?? ''}</span>
				</div>
				<hr />
				<div className="sidebar-options">
					<li className={classNames('profile')}>
						<NavLink to={portalPaths.profile} end={true} onClick={() => props.hide(true)}>
							<span>{t('navigation.menu.profile.label')}</span>
						</NavLink>
					</li>
					<li className={classNames('logout')}>
						<button className="button naked" onClick={logout}>
							<span>{t('navigation.account.logout.label')}</span>
						</button>
					</li>
				</div>
			</div>
		</Sidebar>
	);
};
