import { Action } from '@eag/sales-app-base/build/app/core/NavigationAction';
import { DASHBOARD_PAGE, portalPaths, publicPaths } from '@eag/sales-app-base/build/app/core/routes/paths';
import {
	RouteModel,
	portalRoutesValuesAsFlatArray,
	routesValuesAsFlatArray,
} from '@eag/sales-app-base/build/app/core/routes/routes';
import { loyaltyService } from '@eag/sales-app-base/build/app/core/service/LoyaltyService';
import { useAuthenticationService } from '@eag/sales-app-base/build/app/core/utils/ContextHelper';

import logo from 'eportal-assets/docroot/assets/img/brand/logo-2024-2.svg';
import { observer } from 'mobx-react';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, matchPath, useLocation, useNavigationType } from 'react-router-dom';
import { SidebarComponent } from '../Sidebar';
import { AccountNavItem } from './AccountNavItem';
import BonusPointsHeaderComponent from './BonusPointsHeaderComponent';

enum MenuType {
	NAVIGATION,
	STROM,
}

const Navigation: React.FC = observer(() => {
	const [expanded, setExpanded] = useState([false, true]);
	const location = useLocation();
	const navigationType = useNavigationType() as Action;
	const { t } = useTranslation();
	const [showSidebar, setShowSidebar] = useState(false);
	const loyaltyRegistered = loyaltyService.registered.get();
	const loyaltyPoints = loyaltyService.currentPoints.get()?.points;
	const authenticationService = useAuthenticationService();

	const authenticated = authenticationService?.isAuthenticated();
	const temporary = authenticationService?.currentSession.get().user.temporary;

	const routesWithNavigation = portalRoutesValuesAsFlatArray.filter((route) => route.navigation?.() === true);

	const currentRoute = routesValuesAsFlatArray.find((route) =>
		matchPath({ path: route.path, end: true }, location.pathname)
	);

	useEffect(() => {
		if (!matchPath({ path: portalPaths.base, end: true }, location.pathname) && navigationType !== Action.Replace) {
			setExpanded((prevExpanded) => {
				if (prevExpanded[MenuType.NAVIGATION]) {
					const expanded = [...prevExpanded];
					expanded[MenuType.NAVIGATION] = false;

					return expanded;
				} else {
					return prevExpanded;
				}
			});
		}
	}, [location]);

	useEffect(() => {
		if (expanded[MenuType.NAVIGATION]) {
			document.body.classList.add('locked');
		} else {
			document.body.classList.remove('locked');
		}
	}, [expanded]);

	return (
		<div className={classNames('navigation')}>
			<nav className={classNames('app-navigation')}>
				<a className="logo" href={temporary ? publicPaths.directOrder.base : DASHBOARD_PAGE}>
					<img src={logo} alt={t('navigation.logo.alt')} />
				</a>
				{authenticated && (
					<>
						<SidebarComponent
							visible={showSidebar}
							hide={() => {
								setShowSidebar(false);
							}}
						/>
						<div className={classNames('menu', { expanded: expanded[MenuType.NAVIGATION], temporary: temporary })}>
							<ul className="level-1">
								{!temporary && (
									<div className="pages">
										<li>
											<a href={DASHBOARD_PAGE} rel="noopener noreferrer">
												{t('navigation.menu.homepage.label')}
											</a>
										</li>
										{routesWithNavigation.map((route: RouteModel) => {
											const highlightNavItem = currentRoute?.highlightedNavEntryPath === route.path;
											return (
												<li key={route.name}>
													<NavLink
														to={route.path}
														end={true}
														target={route.externalSite ? '_blank' : undefined}
														className={({ isActive }) => classNames({ active: highlightNavItem || isActive })}>
														{route.name}
													</NavLink>

													{route.path === portalPaths.bonus.base && loyaltyRegistered && (
														<BonusPointsHeaderComponent points={loyaltyPoints ?? 0} />
													)}
												</li>
											);
										})}
									</div>
								)}
								<AccountNavItem />
								{!temporary && (
									<button
										className="burger button default"
										onClick={() => {
											setShowSidebar(!showSidebar);
										}}>
										<span>{t('navigation.menu.navigation.label')}</span>
									</button>
								)}
							</ul>
						</div>
					</>
				)}
			</nav>
		</div>
	);
});

Navigation.displayName = 'Navigation';

export default Navigation;
