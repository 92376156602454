import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAuthenticationService } from '@eag/sales-app-base/build/app//core/utils/ContextHelper';
import { oAuth2PKCEHandler } from '@eag/sales-app-base/build/app/core/factory/OAuth2PKCEHandlerFactory';
import { DASHBOARD_PAGE, publicPaths } from '@eag/sales-app-base/build/app/core/routes/paths';

const LoginPage: React.FC = observer(() => {
	const authenticationService = useAuthenticationService();
	const authenticated = authenticationService.isAuthenticated();
	const location = useLocation();
	const state = location.state as { from?: string };
	const redirectUrl = publicPaths.redirect;
	const showNonPortalUserContent = authenticationService.isPortalUser() === false;
	const { t } = useTranslation();

	useEffect(() => {
		if (authenticated === false) {
			localStorage.setItem('redirectUrl', state?.from ?? DASHBOARD_PAGE);
			oAuth2PKCEHandler.startPKCELogin(redirectUrl);
		}
	}, [authenticated]);

	return (
		<main className="app-main">
			<article className="app-container">
				{showNonPortalUserContent && (
					<>
						<p>{t('login.non_portal_user.info')}</p>
						<button className="button primary" type="button" onClick={() => authenticationService.logout().catch()}>
							{t('login.non_portal_user.logout')}
						</button>
					</>
				)}
			</article>
		</main>
	);
});

LoginPage.displayName = 'LoginPage';

export default LoginPage;
