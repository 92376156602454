import translation_base from '@eag/sales-app-base/build/app/core/i18n/de-AT.json';
import { setI18nInstance } from '@eag/sales-app-base/build/app/core/i18n/i18n';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de_AT from './app/core/i18n/de-AT.json';

i18n
	.use({
		type: 'postProcessor',
		name: 'firstLowercase',
		process: function (value: string) {
			return value.substring(0, 1).toLocaleLowerCase() + value.substring(1);
		},
	})
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		lng: 'de-AT',
		defaultNS: 'translation',
		fallbackNS: 'translation-base',
		debug: import.meta.env.DEV,
		resources: {
			'de-AT': {
				'translation': de_AT,
				'translation-base': translation_base,
			},
		},
		// allow keys to be phrases having `:`, `.`
		keySeparator: false,
		nsSeparator: false,
		returnNull: false,

		interpolation: {
			escapeValue: false, // react already saves from xss
		},
	})
	.catch((e) => console.error('Error during i18n initialization', e));

setI18nInstance(i18n);

export default i18n;
