import { RouteModel, routesValuesAsFlatArray } from '@eag/sales-app-base/build/app/core/routes/routes';
import { breadcrumbStore } from '@eag/sales-app-base/build/app/core/store/BreadcrumbStore';
import { headerStore } from '@eag/sales-app-base/build/app/core/store/HeaderStore';
import React, { useEffect } from 'react';
import { Route, Routes, matchPath, useLocation } from 'react-router-dom';
import video from '../../../../../eportal-assets/docroot/assets/img/background-image/hero-b2b.mp4';
import SimpleHeader from '../../portal/component/header/SimpleHeader';
import Error404Header from './header/Error404Header';

const Header: React.FC = () => {
	const currentPath = useLocation().pathname;

	useEffect(() => {
		const currentRoute = routesValuesAsFlatArray.find((route) =>
			matchPath({ path: route.path, end: true }, currentPath)
		);
		const title = currentRoute?.title ? currentRoute.title() : currentRoute?.name ?? '';
		document.title = title;
		headerStore.setTitle(title);
		breadcrumbStore.updateBreadcrumbsForPath(currentPath);
	});

	return (
		<>
			<div className="background-video-container">
				<div className="header-overlay">
					<video className="background-video" autoPlay loop muted playsInline controlsList="nodownload" src={video} />
				</div>
				<header className="app-header">
					<div className="wrapper">
						<div className="banner">
							<div className="content">
								<Routes>
									{routesValuesAsFlatArray.map((route: RouteModel, index: number) =>
										route.header ? (
											<Route key={index} path={route.path} element={route.header} />
										) : (
											<Route key={index} path={route.path} element={<SimpleHeader />} />
										)
									)}
									<Route element={<Error404Header />} path="*" />
								</Routes>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
};

export default Header;
