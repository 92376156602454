import { portalPaths, publicPaths } from '@eag/sales-app-base/build/app/core/routes/paths';
import { useAuthenticationService } from '@eag/sales-app-base/build/app/core/utils/ContextHelper';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
const Footer: React.FC = observer(() => {
	const authenticationService = useAuthenticationService();

	function resetCache(): void {
		if (authenticationService.isAuthenticated()) {
			authenticationService.resetCache().catch();
		}
	}

	return (
		<footer className="app-footer">
			<div className="contact-list app-wrapper">
				<div className="wrapper">
					<Link to="tel:0800 81 8000">
						<div className="contact support">
							<div className="contact-header">
								<div>
									<h6 className="headline">Servicehotline</h6>
									<p>0800 81 8000</p>
								</div>
								<div className="image image-support" />
							</div>
							<p className="contact-body-text">
								Kostenlose Hotline für Infos zu Strom, Erdgas, Internet, Fernwärme und E-Mobilität.
							</p>
						</div>
					</Link>
				</div>
				<div className="wrapper">
					<Link
						to={
							authenticationService.isAuthenticated()
								? portalPaths.callback
								: 'https://www.energieag.at/Kontakt#Formular-Konzern-Kontaktformular-allgemein'
						}>
						<div className="contact web">
							<div className="contact-header">
								<div>
									<h6 className="headline">Online-Kontakt</h6>
									<p>Kontaktformular</p>
								</div>
								<div className="image image-web" />
							</div>
							<p className="contact-body-text">Hier können Sie Anfragen an das Energie AG-Service Team richten.</p>
						</div>
					</Link>
				</div>
				<div className="wrapper">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.energieag.at/privat/services#Fragen-und-Antworten-zu-den-einzelnen-Themen">
						<div className="contact info">
							<div className="contact-header">
								<div>
									<h6 className="headline">FAQs</h6>
									<p>Häufig gestellte Fragen</p>
								</div>
								<div className="image image-info" />
							</div>
							<p className="contact-body-text">Alles über Services und Produkte bis hin zu Vertragskonten.</p>
						</div>
					</a>
				</div>
			</div>
			<footer className="sitemap">
				<div className="links">
					<div className="block1">
						<a href="mailto:service@energieag.at">service@energieag.at</a>
						<a href="https://www.energieag.at/ueber-uns/konzern/compliance">Compliance</a>
						<Link to={publicPaths.privacy}>Datenschutz</Link>
						<Link to={publicPaths.impress}>Impressum</Link>
					</div>
					<div className="block2">
						<p>Energie AG Oberösterreich Böhmerwaldstraße 3, 4020 Linz, Österreich</p>
						<span onDoubleClick={resetCache}>© 2024 Energie AG Vertrieb GmbH</span>
					</div>
				</div>
			</footer>
		</footer>
	);
});

Footer.displayName = 'Footer';

export default Footer;
