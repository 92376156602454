import { headerStore } from '@eag/sales-app-base/build/app/core/store/HeaderStore';
import { observer } from 'mobx-react';
import React from 'react';

const SimpleHeader: React.FC = observer(() => {
	return (
		<React.Fragment>
			<h3>{headerStore.title}</h3>
		</React.Fragment>
	);
});

SimpleHeader.displayName = 'SimpleHeader';

export default SimpleHeader;
