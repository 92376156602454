import React from 'react';
import { useTranslation } from 'react-i18next';

const Error404Header: React.FC = () => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<h3>{t('error.404.header.header')}</h3>
			<p>{t('error.404.header.text')}</p>
		</React.Fragment>
	);
};

export default Error404Header;
